import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getAds = async (data) => {
  const response = await $authApi.get(apiRoutes.ADS, {
    params: data,
  });

  return response?.data;
};

const getAd = async (id) => {
  const response = await $authApi.get(apiRoutes.AD, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createAd = async (data) => {
  const response = await $authApi.postForm(apiRoutes.AD_CREATE, data);
  return response?.data;
};

const editAd = async (data) => {
  const response = await $authApi.postForm(apiRoutes.ADS, data);
  return response?.data;
};

const deleteAd = async (ids) => {
  const response = await $authApi.delete(apiRoutes.ADS, {
    data: { ids },
  });
  return response?.data;
};

export { getAds, getAd, createAd, editAd, deleteAd };
