import React, { useCallback, useState, useEffect } from "react";
import { Button, Form, Card, Col, Row, Container } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import { createCategory } from "../../services/category";
import DragDropFile from "../../components/DragDropFile";
import Textarea from "../../components/UI/Textarea";
import ImageCropper from "../../components/Cropper";
import { generateUrl } from "../../helpers/all";

const CreateCategory = () => {
  const navigate = useNavigate();

  const [editImageProduct, setEditImageProduct] = useState({
    show: false,
    data: [],
  });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    createCategory(data)
      .then((res) => {
        NotificationManager.success("Категория успешно создана");
        navigate("/category/" + res.id);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  useEffect(() => {
    setValue("uid", generateUrl(data?.title));
  }, [data?.title]);

  return (
    <Container fluid={true}>
      <Meta title="Создать категорию" />
      <section className="box">
        <div>
          <Link
            to="/categories"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Создать категорию</h3>
          <button
            className="btn-primary"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Сохранить
          </button>
        </div>

        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Input
                defaultValue={data.title}
                label="Название"
                name="title"
                errors={errors}
                register={register}
                validation={{
                  required: "Обязательное поле",
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Input
                value={data?.uid ?? generateUrl(data?.title)}
                label="Ссылка"
                name="uid"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите имя пользователя",
                  minLength: {
                    value: 2,
                    message: "Минимально 2 символа",
                  },
                  maxLength: {
                    value: 255,
                    message: "Максимально 255 символов",
                  },
                  pattern: {
                    value: /^[a-z0-9_]+$/,
                    message: "Неверный формат (Только a-z0-9_)",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Textarea
                defaultValue={data.desc}
                label="Описание"
                name="desc"
                errors={errors}
                rows={6}
                register={register}
              />
            </div>
          </Col>
          <Col md={12}>
            <Form.Check className="mb-3">
              <Form.Check.Input
                type="checkbox"
                name="options.from"
                id="options_from"
                value={true}
                defaultChecked={data?.options?.from}
                {...register("options.from")}
              />
              <Form.Check.Label htmlFor="options_from" className="ms-3">
                Добавить адрес откуда
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default CreateCategory;
