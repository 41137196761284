import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Container,
} from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoAdd,
  IoAddOutline,
  IoChevronBackOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";

import Textarea from "../../components/UI/Textarea";

import CustomModal from "../../components/utils/CustomModal";
import { generateUrl } from "../../helpers/all";
import { getImageURL } from "../../helpers/image";
import {
  createOption,
  createParam,
  createRegion,
  createServer,
  deleteOption,
  deleteParam,
  deleteRegion,
  deleteServer,
  editCategory,
  editOption,
  editOptionStatus,
  editParam,
  editParamStatus,
  editRegion,
  editRegionStatus,
  editServer,
  editServerStatus,
  getCategory,
} from "../../services/category";

function createOptionFun(data, idProp, parentProp) {
  var tree = Object.fromEntries(
    data.map((n) => [n[idProp], { ...n, children: [] }])
  );

  return Object.values(tree).filter(
    (n) => !(tree[n[parentProp]] && tree[n[parentProp]].children.push(n))
  );
}

const EditCategory = () => {
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(true);

  const [showDelete, setShowDelete] = useState({
    show: false,
    data: false,
    event: false,
  });

  const [showEditParam, setShowEditParam] = useState({
    show: false,
    data: false,
  });
  const [showCreateParam, setShowCreateParam] = useState({
    show: false,
  });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      options: {
        from: false,
        status: 1,
        priority: 0,
      },
    },
  });

  const form = useWatch({ control });

  const getData = useCallback(() => {
    getCategory(categoryId)
      .then((res) => {
        res.media = getImageURL({ path: res.media, type: "category" });

        // res.params =
        //   res?.params?.length > 0
        //     ? res.params
        //         .map((e) => {
        //           if (e?.options?.length > 0) {
        //             e.options = createOptionFun(e.options, "id", "parent").sort(
        //               function (a, b) {
        //                 return a.priority - b.priority;
        //               }
        //             );
        //           }
        //           return e;
        //         })
        //         .sort(function (a, b) {
        //           return a.priority - b.priority;
        //         })
        //     : [];
        if (!res?.uid && res.title) {
          res.uid = generateUrl(res.title);
        }
        reset(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [categoryId]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      await editCategory(data)
        .then(() => {
          NotificationManager.success("Каталог успешно обновлен");
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [categoryId]
  );

  // Параметры
  const onCreateParam = useCallback(
    (data) => {
      createParam({ ...data, id: form.id })
        .then(() => {
          NotificationManager.success("Параметр успешно добавлен");
          getData();
          setShowCreateParam({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditParam = useCallback((data) => {
    editParam(data)
      .then(() => {
        NotificationManager.success("Параметр успешно обновлен");
        getData();
        setShowEditParam({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditParamStatus = useCallback((data) => {
    editParamStatus(data)
      .then(() => {
        NotificationManager.success("Статус параметра успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteParam = useCallback((data) => {
    deleteParam(data)
      .then(() => {
        NotificationManager.success("Параметр успешно удален");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  const onEditOptionStatus = useCallback((data) => {
    editOptionStatus(data)
      .then(() => {
        NotificationManager.success("Статус опции успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteOption = useCallback((data) => {
    deleteOption(data)
      .then(() => {
        NotificationManager.success("Опция успешно удалена");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!form) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой категории нет</h3>
      </Info>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Редактировать категорию" />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link
              to="/categories"
              className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
            >
              <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
            </Link>
          </div>
        </div>
        <Row>
          <Col md={12} className="mb-4">
            <h3 className="mb-4">Редактировать категорию</h3>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Input
                    defaultValue={form.title}
                    label="Название"
                    name="title"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Обязательное поле",
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Input
                    value={form.uid}
                    label="Ссылка"
                    name="uid"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите ссылку",
                      minLength: {
                        value: 2,
                        message: "Минимально 2 символа",
                      },
                      maxLength: {
                        value: 255,
                        message: "Максимально 255 символов",
                      },
                      pattern: {
                        value: /^[a-z0-9_]+$/,
                        message: "Неверный формат (Только a-z0-9_)",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-4">
                  <Textarea
                    defaultValue={form.desc}
                    label="Описание"
                    name="desc"
                    errors={errors}
                    rows={6}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={12}>
                <Form.Check className="mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="options.from"
                    id="options_from"
                    defaultChecked={form?.options?.from}
                    onChange={(e) => setValue("options.from", e.target.checked)}
                  />
                  <Form.Check.Label htmlFor="options_from" className="ms-3">
                    Добавить адрес откуда
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col md={12}>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    name="status"
                    id="status"
                    value={1}
                    defaultChecked={form?.status}
                    {...register("status")}
                  />
                  <Form.Check.Label htmlFor="status" className="ms-3">
                    Вкл\Выкл показ
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-self-end ">
              <Button
                className="mt-3"
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить изменения
              </Button>
            </div>
          </Col>
          <Col lg={12} xl={12}>
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between">
                <h3>
                  Параметры{" "}
                  <Badge bg="secondary" className="fs-07">
                    {form?.params?.length ?? 0}
                  </Badge>
                </h3>
                <Button
                  onClick={() => setShowCreateParam({ show: true })}
                  className="btn-primary-outline"
                >
                  <IoAdd />
                </Button>
              </Card.Header>
              <Card.Body className="py-0">
                {form.params.length > 0 &&
                  form.params.map((e, index) => {
                    index++;
                    return (
                      <div className="mb-4">
                        <div className="mb-2 d-flex align-items-center justify-content-between">
                          <a
                            className="d-flex align-items-center"
                            onClick={() =>
                              setShowEditParam({
                                show: true,
                                data: e,
                              })
                            }
                          >
                            <MdOutlineEdit className="text-muted me-1" />

                            <span className="me-1 fw-7">{index}.</span>
                            <span className="fw-7">{e.title}</span>
                          </a>
                          <div className="d-flex align-items-center">
                            <Form.Check
                              className="me-2"
                              type="switch"
                              id="custom-switch-param"
                              defaultChecked={e.status}
                              onChange={(e2) =>
                                onEditParamStatus({
                                  id: e.id,
                                  status: e2.target.checked,
                                })
                              }
                            />
                            <Button
                              onClick={() =>
                                setShowCreateParam({
                                  show: true,
                                  parentId: e.id,
                                })
                              }
                              className="btn-light btn-xs me-2"
                            >
                              <IoAdd />
                            </Button>
                            <Button
                              onClick={() =>
                                setShowDelete({
                                  show: true,
                                  data: e,
                                  event: onDeleteParam,
                                })
                              }
                              className="btn-danger-outline btn-xs"
                            >
                              <IoTrashOutline />
                            </Button>
                          </div>
                        </div>
                        {e?.children?.length > 0 ? (
                          e.children.map((children, index) => {
                            index++;

                            return (
                              <>
                                <div className="d-flex align-items-center justify-content-between py-1 ps-3 fw-6">
                                  <a
                                    className="d-flex fs-09 align-items-center"
                                    onClick={() =>
                                      setShowEditParam({
                                        show: true,
                                        data: children,
                                      })
                                    }
                                  >
                                    {!children?.parentId && (
                                      <MdOutlineEdit className="text-muted me-1" />
                                    )}

                                    <span
                                      bg={
                                        children.status
                                          ? "success"
                                          : "secondary"
                                      }
                                      className="me-1"
                                    >
                                      {index}.
                                    </span>

                                    <span>{children.title}</span>
                                  </a>
                                  <div className="d-flex align-items-center">
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      checked={children.status}
                                      onChange={(e) =>
                                        onEditParamStatus({
                                          id: children.id,
                                          status: e.target.checked,
                                        })
                                      }
                                    />

                                    <a
                                      className="ms-2 text-danger"
                                      onClick={() =>
                                        setShowDelete({
                                          show: true,
                                          data: children,
                                          event: onDeleteParam,
                                        })
                                      }
                                    >
                                      <IoTrashOutline />
                                    </a>
                                  </div>
                                </div>
                                {children?.children?.length > 0 &&
                                  children.children.map((item, index) => {
                                    index++;
                                    return (
                                      <>
                                        <div
                                          className={
                                            "d-flex align-items-center justify-content-between py-1 ps-4 ms-3 border-left-2 fs-09 text-muted"
                                          }
                                        >
                                          <a
                                            className="d-flex fs-09 align-items-center"
                                            onClick={() =>
                                              setShowEditOption({
                                                show: true,
                                                data: item,
                                              })
                                            }
                                          >
                                            <span
                                              bg={
                                                item.status
                                                  ? "success"
                                                  : "secondary"
                                              }
                                              className="me-1"
                                            >
                                              {index}.
                                            </span>

                                            <span>{item.title}</span>
                                          </a>
                                          <div className="d-flex align-items-center">
                                            <Form.Check
                                              type="switch"
                                              id="custom-switch"
                                              checked={item.status}
                                              onChange={(e) =>
                                                onEditParamStatus({
                                                  id: item.id,
                                                  status: e.target.checked,
                                                })
                                              }
                                            />

                                            <a
                                              className="ms-2 text-danger"
                                              onClick={() =>
                                                setShowDelete({
                                                  show: true,
                                                  data: item,
                                                  event: onDeleteParam,
                                                })
                                              }
                                            >
                                              <IoTrashOutline />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="border-left-2 ps-2 ms-3">
                                          {item?.children?.length > 0 &&
                                            item.children.map(
                                              (child, index) => {
                                                index++;
                                                return (
                                                  <div
                                                    className={
                                                      "d-flex align-items-center justify-content-between py-1 ps-3 ms-3 border-left-2 fs-09 text-muted"
                                                    }
                                                  >
                                                    <a
                                                      className="d-flex fs-09 align-items-center"
                                                      onClick={() =>
                                                        setShowEditParam({
                                                          show: true,
                                                          data: child,
                                                        })
                                                      }
                                                    >
                                                      <span
                                                        bg={
                                                          child.status
                                                            ? "success"
                                                            : "secondary"
                                                        }
                                                        className="me-1"
                                                      >
                                                        {index}.
                                                      </span>

                                                      <span>{child.title}</span>
                                                    </a>
                                                    <div className="d-flex align-items-center">
                                                      <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={child.status}
                                                        onChange={(e) =>
                                                          onEditOptionStatus({
                                                            id: child.id,
                                                            status:
                                                              e.target.checked,
                                                          })
                                                        }
                                                      />

                                                      <a
                                                        className="ms-2 text-danger"
                                                        onClick={() =>
                                                          setShowDelete({
                                                            show: true,
                                                            data: child,
                                                            event:
                                                              onDeleteOption,
                                                          })
                                                        }
                                                      >
                                                        <IoTrashOutline />
                                                      </a>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}

                                          <div className="d-flex align-items-center justify-content-between ps-3 ms-3 border-left-2 fs-09">
                                            <a
                                              className="my-2 fs-09 d-flex align-items-center"
                                              onClick={() =>
                                                setShowCreateParam({
                                                  ...item,
                                                  show: true,
                                                  parentId: item.id,
                                                })
                                              }
                                            >
                                              <IoAddOutline /> Добавить
                                            </a>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}

                                {!e?.parentId && (
                                  <div className="d-flex align-items-center justify-content-between ps-4 ms-3 border-left-2 fs-09">
                                    <a
                                      className="my-2 fs-09 d-flex align-items-center"
                                      onClick={() =>
                                        setShowCreateParam({
                                          ...children,
                                          show: true,
                                          parentId: children.id,
                                        })
                                      }
                                    >
                                      <IoAddOutline /> Добавить
                                    </a>
                                  </div>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <div className="fs-08 text-muted">Нет значений</div>
                        )}
                      </div>
                    );
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <CustomModal
          title="Новый параметр"
          show={showCreateParam.show}
          setShow={(e) => setShowCreateParam({ show: e })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowCreateParam({ show: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onCreateParam(showCreateParam)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              onChange={(e) =>
                setShowCreateParam((prev) => ({
                  ...prev,
                  title: e,
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              placeholder={form?.params?.length + 1}
              onChange={(e) =>
                setShowCreateParam((prev) => ({
                  ...prev,
                  priority: e,
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-param-create"
            type="switch"
            className="mb-2"
            defaultChecked={true}
            onChange={(e) =>
              setShowCreateParam((prev) => ({
                ...prev,
                status: e.target.checked,
              }))
            }
          />
        </CustomModal>

        <CustomModal
          title="Редактирование параметра"
          show={showEditParam.show}
          setShow={(e) => setShowEditParam({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowEditParam({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onEditParam(showEditParam.data)}>
                Сохранить
              </Button>
            </>
          }
        >
          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              defaultValue={showEditParam.data.title}
              onChange={(e) =>
                setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              defaultValue={showEditParam.data.priority}
              onChange={(e) =>
                setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-param-edit"
            className="mb-2"
            type="switch"
            defaultChecked={showEditParam.data.status}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
        </CustomModal>
        <CustomModal
          title="Подтвердите удаление"
          show={showDelete.show}
          setShow={(e) => setShowDelete({ show: e, data: false, event: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() =>
                  setShowDelete({ show: false, data: false, event: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  showDelete.event && showDelete.event(showDelete.data)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить данный элемент? Восстановить данные будет не
          возможно.
        </CustomModal>
      </section>
    </Container>
  );
};

export default EditCategory;
