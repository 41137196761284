import React, { useCallback, useLayoutEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
// import Status from "../../components/StatusOrder";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import { editAd, getAd } from "../../services/ads";

const AdEdit = () => {
  const { adId } = useParams();
  const [loading, setLoading] = useState(true);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmitBlocked = useCallback(() => {
    editAd({
      id: data.id,
      status: data.status === 1 || data.status === 0 ? -1 : 1,
    })
      .then((res) => {
        NotificationManager.success(
          data.status === -1
            ? "Объявление разблокировано"
            : "Объявление заблокировано"
        );
        reset(res);
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      );
  }, [data, adId]);

  useLayoutEffect(() => {
    getAd(adId)
      .then((res) => {
        reset(res);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  }, [adId]);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого заказа нет</h3>
      </Info>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title={"Объявление #" + data.uid} />
      <section className="box">
        <div>
          <Link
            to="/ads"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад в список
          </Link>
        </div>
        <div>
          <h1 className="mb-2">
            #{data.uid} <span className="fs-07 text-muted">(id{data.id})</span>
          </h1>
          {data.title && <p>{data.title}</p>}
          {data.desc && <p>{data.desc}</p>}
          {data.view && <p>Просмотров: {data.view}</p>}
          {data.count > 0 && <p>Кол-во: {data.count}</p>}
          {data.price && <p>Цена: {data.price}</p>}
          {data.view && <p>Просмотров: {data.view}</p>}
          <hr />
          {data?.category?.title && <p>Категория: {data.category.title}</p>}
          {data?.options?.length > 0 && (
            <p>
              <span className="fw-6">Опции:</span>{" "}
              {data.options.map((e, index) => {
                index++;
                return (
                  <p>
                    {e.option.title} {e.value}
                  </p>
                );
              })}
            </p>
          )}
          <hr />
          <Row className="mt-3">
            <Col>
              {data?.status === -1 ? (
                <Button
                  className="btn btn-primary w-100"
                  onClick={onSubmitBlocked}
                >
                  Разблокировать
                </Button>
              ) : (
                <Button
                  className="btn btn-danger w-100"
                  onClick={onSubmitBlocked}
                >
                  Заблокировать
                </Button>
              )}
            </Col>
            {/* <Col>
              <a
                className="btn btn-primary w-100"
                target="_blank"
                href={process.env.REACT_APP_SITE_URL + `/lot/${data?.uid}`}
              >
                Перейти на сайт
              </a>
            </Col> */}
          </Row>
        </div>
      </section>
    </Container>
  );
};

export default AdEdit;
