import React from "react";
import { Badge } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import IconMain from "./svgs/IconMain";
import IconLots from "./svgs/IconLots";
import IconStopList from "./svgs/IconStopList";
import IconSettings from "./svgs/IconSettings";
import IconTickets from "./svgs/IconTickets";
import IconGames from "./svgs/IconGames";
import { isRole } from "../helpers/member";
import { convertColor } from "../helpers/convertColor";

const AdminMenu = ({ onClick }) => {
  const user = useSelector((state) => state.auth.user);
  const notification = useSelector((state) => state.notification);
  const role = isRole(user);

  return (
    <>
      <div className="admin-box">
        <Link to="/">
          <img src="/logo.png" className="logo" />
        </Link>
        <div>
          <h6>
            {user?.firstName ?? user?.email ?? user?.phone ?? "Член команды"}
          </h6>
          <div
            className="role"
            style={
              role?.role?.color && {
                color: role.role.color,
                backgroundColor: convertColor(role.role.color, 0.08),
              }
            }
          >
            {role?.role?.title ?? "Роли нет"}
          </div>
        </div>
      </div>

      <nav className="layout-grid-left" onClick={onClick}>
        <Accordion as="ul" defaultActiveKey="0">
          <Accordion.Item as="li" eventKey="0">
            <Accordion.Header>
              <IconMain className="orange" />
              <span>Основное</span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {/* <li>
                  <Link to="/categories">Категории</Link>
                </li> */}
                <li>
                  <Link to="/articles">Документы</Link>
                </li>
                <li>
                  <Link to="/users">Пользователи</Link>
                </li>
                {/* <li>
                  <Link to="/histories">История</Link>
                </li> */}
                {/* <li>
                  <Link to="/tasks">
                    Задачи
                    {notification?.task > 0 && (
                      <Badge className="ms-2" bg="danger">
                        {notification.task}
                      </Badge>
                    )}
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/dialogs/general">
                    Диалоги
                    {notification?.message > 0 && (
                      <Badge className="ms-2" bg="danger">
                        {notification.message}
                      </Badge>
                    )}
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/members">Администраторы</Link>
                </li> */}
                {/* <li>
                  <Link to="/members/log">Лог действий администраторов</Link>
                </li> */}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {/* 
          <Accordion.Item as="li" eventKey="1" className="li-games">
            <Accordion.Header>
              <IconGames />
              <span>Игры</span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <Link to="games">Список игр</Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item> */}

          <Accordion.Item as="li" eventKey="2">
            <Accordion.Header>
              <IconLots className="teal" />
              <span>Объявления</span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <Link to="/ads">Список объявления</Link>
                </li>
                <li>
                  <Link to="/deals">Список заказов</Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          {role != 4 && (
            <Accordion.Item as="li" eventKey="3">
              <Accordion.Header>
                <IconLots className="teal" />
                <span>Финансы</span>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link to="/transactions">Транзакции</Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
        {/* <Link to="/stopwords" className="layout-grid-left-link">
          <IconStopList className="red" />
          <span>Запретные слова</span>
        </Link> */}
      </nav>
    </>
  );
};

export default AdminMenu;
