import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Orders from "../pages/order";
import OrderEdit from "../pages/order/Edit";
import Notifications from "../pages/notification";
import NotificationCreate from "../pages/notification/Create";
import Categories from "../pages/category";
import CategoryCreate from "../pages/category/Create";
import CategoryEdit from "../pages/category/Edit";
import Ads from "../pages/ads";
import AdEdit from "../pages/ads/Edit";
import Users from "../pages/user";
import UserEdit from "../pages/user/Edit";
import Documents from "../pages/document";
import DocumentCreate from "../pages/document/Create";
import DocumentEdit from "../pages/document/Edit";
import Members from "../pages/member";
import MemberEdit from "../pages/member/Edit";
import Account from "../pages/account";
import { useSelector } from "react-redux";
import Dialogs from "../pages/dialog";
import MemberCreate from "../pages/member/Create";
import Reports from "../pages/reports";
import Reviews from "../pages/reviews";
import StopWord from "../pages/stopWord";
import CreateStopWord from "../pages/stopWord/Create";
import EditStopWord from "../pages/stopWord/Edit";
import Tasks from "../pages/task";
import Transactions from "../pages/transaction";
import Histories from "../pages/history";
import EditTask from "../pages/task/Edit";

const AdminRouter = () => {
  const role = useSelector((state) => state.auth.user.role);

  return (
    <Routes>
      <Route path="/">
        <Route path="account" element={<Account />} />
        <Route path="stopwords" element={<StopWord />} />
        <Route path="stopwords/create" element={<CreateStopWord />} />
        <Route path="stopword/:stopwordId" element={<EditStopWord />} />
        <Route path="histories" element={<Histories />} />
        {role != 4 && (
          <>
            <Route path="tasks" element={<Tasks />} />
            <Route path="task/:taskId" element={<EditTask />} />

            <Route path="dialogs" element={<Dialogs />} />
            <Route path="dialogs/:dialogId" element={<Dialogs />} />

            <Route path="notifications" element={<Notifications />} />
            <Route
              path="notifications/create"
              element={<NotificationCreate />}
            />

            <Route path="users" element={<Users />} />
            <Route path="user/:userId" element={<UserEdit />} />
            <Route path="members" element={<Members />} />
            {role == 1 && (
              <>
                <Route path="members/create" element={<MemberCreate />} />
                <Route path="member/:memberId" element={<MemberEdit />} />
              </>
            )}

            <Route path="reviews" element={<Reviews />} />

            <Route path="reports" element={<Reports />} />
          </>
        )}
        <Route path="ads" element={<Ads />} />
        <Route path="ad/:adId" element={<AdEdit />} />

        <Route path="articles" element={<Documents />} />
        <Route path="article/:documentId" element={<DocumentEdit />} />
        <Route path="articles/create" element={<DocumentCreate />} />

        <Route path="categories" element={<Categories />} />
        <Route path="category/:categoryId" element={<CategoryEdit />} />
        <Route path="categories/create" element={<CategoryCreate />} />

        {role != 4 && (
          <>
            <Route path="deals" element={<Orders />} />
            <Route path="deal/:orderId" element={<OrderEdit />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transaction/:transactionId" element={<OrderEdit />} />
          </>
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
