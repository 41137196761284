import React, { useState, useLayoutEffect } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Home from "./Home";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import { IoArrowForwardCircle } from "react-icons/io5";
import { getStatistics } from "../services/statistic";

const Admin = () => {
  const auth = useSelector((state) => state?.auth);

  if (!auth.isAuth) {
    return <Home />;
  }

  const [statistic, setStatistic] = useState({
    users: 0,
    ads: 0,
    categories: 0,
    orders: 0,
    total: 0,
  });

  useLayoutEffect(() => {
    getStatistics({
      user: true,
      ads: true,
      order: true,
      category: true,
    }).then((res) => setStatistic(res));
  }, []);

  return (
    <>
      <Meta title="Главная" />
      <Container fluid={true}>
        <section className="mb-4">
          <Row>
            <Col md={3}>
              <Link to="/ads" className="analytics lots mb-3">
                <h6 className="mb-1">Всего объявлений</h6>
                <div className="num">{statistic.ads ?? 0}</div>
                <div className="btns">
                  <button type="button">
                    <IoArrowForwardCircle />
                  </button>
                  <button type="button" className="px-2 ms-2">
                    подробнее
                  </button>
                </div>
              </Link>
            </Col>
            <Col md={3}>
              <Link to="/users" className="analytics user mb-3">
                <h6 className="mb-1">Пользователи</h6>
                <div className="num">{statistic.users ?? 0}</div>
                <div className="btns">
                  <button type="button">
                    <IoArrowForwardCircle />
                  </button>
                  <button type="button" className="px-2 ms-2">
                    подробнее
                  </button>
                </div>
              </Link>
            </Col>
            <Col md={3}>
              <Link to="/deals" className="analytics deals mb-3">
                <h6 className="mb-1">Заказы</h6>
                <div className="num">{statistic.orders ?? 0}</div>
                <div className="btns">
                  <button type="button">
                    <IoArrowForwardCircle />
                  </button>
                  <button type="button" className="px-2 ms-2">
                    подробнее
                  </button>
                </div>
              </Link>
            </Col>
            <Col md={3}>
              <Link to="/deals" className="analytics summ mb-3">
                <h6 className="mb-1">Сумма заказов</h6>
                <div className="num">{statistic.total ?? 0}</div>
                <div className="btns">
                  <button type="button">
                    <IoArrowForwardCircle />
                  </button>
                  <button type="button" className="px-2 ms-2">
                    подробнее
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </section>

        {/* <section className="box">
          <Row>
            <Col md={4}>
              <Card body>
                <h5 className="mb-3 fw-6 h6">Статистика по задачам</h5>
                <div className="d-flex text-muted fs-09">Пока ничего нет</div>
              </Card>
            </Col>
            <Col md={4}>
              <Card body>
                <h5 className="mb-3 fw-6 h6">Топ админов</h5>
                <div className="d-flex text-muted fs-09">Пока ничего нет</div>
              </Card>
            </Col>
            <Col md={4}>
              <Card body>
                <h5 className="mb-3 fw-6 h6">Топ пользователей</h5>
                <div className="d-flex text-muted fs-09">Пока ничего нет</div>
              </Card>
            </Col>
          </Row>
        </section> */}
      </Container>
    </>
  );
};

export default Admin;
